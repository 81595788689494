import DataTable from "react-data-table-component";
import Loader from "../../../../Common/Loader";
import Vacio from "../../../../Common/Vacio";

const NnProductsTable = ({ formData, setFormData, handleProductDelete }) => {
  // console.log(formData);
  const queriesColumns = [
    {
      name: "Descripción",
      selector: (row) => row.descripcion,
      // extra: (row) => console.log("row", row),
      center: false,
      wrap: false,
    },
    {
      name: "Precio Unitario",
      selector: (row) => `USD ${parseFloat(row.precio).toFixed(2)}`,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "Cantidad",
      selector: (row) => `${row.cantidad}`,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "Total",
      selector: (row) => `USD ${parseFloat(row.precio * row.cantidad).toFixed(2)}`,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "Acciones",
      selector: (row) => [
        <button
          key={3}
          className="btn text-dark w-auto p-1 mx-1 my-0"
          type="button"
          title="Borrar"
          onClick={() => handleProductDelete(row.id, row.pedido)}
        >
          <i className="fa fa-trash f-16 px-1" />
        </button>,
      ],
      center: true,
      hide: "md",
      compact: true,
    },
  ];

  return (
    <div className="my-3 table-queries" style={{ overflow: "hidden" }}>
      {/* {console.log("formData", formData)} */}
      <DataTable
        columns={queriesColumns}
        data={formData}
        striped
        customStyles={{
          rows: {
            highlightOnHoverStyle: {
              backgroundColor: "rgb(230, 244, 244)",
              borderBottomColor: "#FFFFFF",
              outline: "1px solid #FFFFFF",
            },
          },
          pagination: {
            style: {
              border: "none",
            },
          },
        }}
        pointerOnHover
        highlightOnHover
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        persistTableHead
      />
    </div>
  );
};

export default NnProductsTable;
