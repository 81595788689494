import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import ProductsTable from "./ProductsTable";
import MyModal from "../../../../Common/MyModal";
import Loader from "../../../../Common/Loader";
import Vacio from "../../../../Common/Vacio";
import DataContext from "../../../../../_helper/DataContext";
import useGoToLogin from "../../../../../_helper/hooks/useGoToLogin";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Configura los estilos para las notificaciones de error
toast.configure({
  style: {
    backgroundColor: "#fc4f59", // Rojo claro
    color: "black",
  },
});


const Productos = ({ handleChange, formData, setFormData }) => {
  const notify = (message) => toast(message);
  const notifyError = (message) => {
    toast.error(message);
  };
  const { orderID } = useParams();
  const [precio, setPrecio] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [id, setIdDetalle] = useState('');
  const [producto, setIdProducto] = useState('');
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const [newQuery, setNewQuery] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [inputMsg, setInputMsg] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const { goToLogin } = useGoToLogin();
  const {
    data,
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    setPage,
    setENDPOINT,
  } = useContext(DataContext);

  const queriesColumns = [
    {
      name: "Seleccionar",
      selector: (row) => [
        row.stock <= 0 ? (
          <p key={1}>Sin stock</p>
        ) : (
          <button
            key={2}
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Seleccionar"
            onClick={(e) => handleProduct(row)}
          >
            <i className="fa fa-check f-16 px-1" />
          </button>
        ),
      ],
      center: true,
      compact: true,
    },
    {
      name: "ID",
      selector: (row) => row.id,
      center: false,
      wrap: true,
      grow: 0,
    },
    {
      name: "Codigo",
      selector: (row) => row.codigo,
      center: false,
      wrap: true,
      grow: 0,
    },
    {
      name: "Producto",
      selector: (row) => row.nombre,
      center: false,
      wrap: false,
      grow: 4
    },
    {
      name: "Marca",
      selector: (row) => row.nombreMarca,
      center: false,
      wrap: false,
      grow: 3
    },
    {
      name: "Color",
      selector: (row) => row.colorNombre,
      center: false,
      wrap: false,
    },
    {
      name: "Stock",
      selector: (row) => row.stock,
      center: false,
      wrap: false,
    }
  ];

  const getId = async (e) => {
    if (e.keyCode !== 13) {
      return;
    }
    try {

      const response = await axios(
        `${initialENDPOINT}/producto/${formData.idProducto}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          redirect: "follow",
        }
      );
      if (response.data.status === 201) {
        isMounted &&
          setFormData((prevalue) => {
            return {
              ...prevalue,
              productoNombre: "Producto no encontrado",
              nombreMarca: "Marca no encontrada",
              precioProducto: "",
              cantidadProducto: 0,
              stockProducto: "",
            };
          });
      }
      if (response.data.data.status === 200) {
        isMounted &&
          setFormData((prevalue) => {
            return {
              ...prevalue,
              productoNombre: response.data.data.message[0].nombre,
              nombreMarca: response.data.data.message[0].nombreMarca,
              precioProducto: response.data.data.message[0].precio,
              stockProducto: response.data.data.message[0].stock,
              producto: response.data.data.message[0].id
            };
          });
        setIdProducto(response.data.data.message[0].id);
        setIsDisabled(false);
      }
    } catch (error) {
      console.log(error);
      isMounted &&
        setFormData((prevalue) => {
          return {
            ...prevalue,
            productoNombre: "Producto no encontrado",
            nombreMarca: "Marca no encontrada",
            precioProducto: "",
            cantidadProducto: 0,
            stockProducto: "",
          };
        });
    }
  };

  const handleProduct = ({
    id,
    codigo,
    nombre,
    precio,
    marca,
    color,
    tamano,
    stock,
  }) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        idProducto: id,
        codigoProducto: codigo,
        productoNombre: nombre,
        nombreMarca: marca,
        precioProducto: precio,
        marcaProducto: marca,
        colorProducto: color,
        tamanoProducto: tamano,
        stockProducto: stock,
      };
    });
    setIsDisabled(false);
    setNewQuery(false);

    document.getElementById("codigoProducto").value = codigo;
  };

  const handleProductAdd = async () => {

    const url = `${initialENDPOINT}/pedidodetalle`;

    const datos = {
      pedido: orderID,
      producto: formData.idProducto,
      precio: formData.precioProducto,
      cantidad: formData.cantidadProducto || 1,
      codigo: formData.codigoProducto
    };
    let response;
    try {
      response = await axios.post(url, datos, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      notify(`Producto agregado con exito!`);
    } catch (error) {
      console.info(response);
      console.error('Error:', error.message);
    }

    getProductsList();

    var codigoProducto = document.getElementById('codigoProducto');
    codigoProducto.value = '';

  };

  const getProductsList = async () => {

    try {
      const response = await axios.get(`${initialENDPOINT}/pedidodetalle/pedido/${orderID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          redirect: "follow",
        }
      );

      setFormData((prevalue) => ({
        ...prevalue,
        detalle: [],
        idProducto: "",
        codigo: "",
        productoNombre: "",
        precioProducto: "",
        cantidadProducto: 0,
      }));

      response.data.data.map((item) => {

        const nuevoProducto = {
          id: item.id,
          producto: item.idProducto,
          codigo: item.codigo,
          productoNombre: item.productoNombre,
          precio: item.precioProducto,
          cantidad: item.cantidadProducto,
          total: item.precioProducto * item.cantidadProducto,
        };

        setFormData((prevalue) => ({
          ...prevalue,
          detalle: [...prevalue.detalle, nuevoProducto],
          producto: "",
          codigo: "",
          productoNombre: "",
          nombreMarca: "",
          precio: "",
          cantidad: 0
        }));

      });
      setInputMsg("");
    } catch (error) {
      console.error("Error fetching data:", error);
    }

  }

  const handleProductDelete = ({ id }) => {
    axios.delete(`${initialENDPOINT}/pedidodetalle/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        getProductsList();
        notify(`Producto eliminado!`);
      });
  };


  const handleProductEdit = ({ id, producto, precio, cantidad }) => {
    setModalOpen(true);
    setIdDetalle(id);
    setPrecio(precio);
    setCantidad(cantidad);
    setIdProducto(producto);

  };

  const handleQuantity = () => {
    const cantidadDeArticulos = formData.detalle.reduce(
      (acc, curr) => acc + 1,
      0
    );
    const cantidadDeUnidades = formData.detalle.reduce(
      (acc, curr) => acc + parseInt(curr.cantidad),
      0
    );

    setFormData((prevalue) => {
      return {
        ...prevalue,
        cantidadDeArticulos: cantidadDeArticulos,
        cantidadDeUnidades: cantidadDeUnidades,
      };
    });
  };

  const handlePageChange = (page) => {
    setENDPOINT(
      `${initialENDPOINT}/producto?pagina=${page}&cantidad=${perPage}&nombre=${encodeURIComponent(formData.productoNombre || '')}&nombreMarca=${encodeURIComponent(formData.nombreMarca || '')}`
    );
  };
  
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
    await setENDPOINT(
      `${initialENDPOINT}/producto?pagina=${page}&cantidad=${newPerPage}&nombre=${encodeURIComponent(formData.productoNombre || '')}&nombreMarca=${encodeURIComponent(formData.nombreMarca || '')}`
    );
  };

  const handleCodigoProducto = async (codigo, e) => {
    if (e.keyCode !== 13) {
      return;
    }
    try {

      let urlCodigo = '';
      if (codigo) {
        urlCodigo = `${initialENDPOINT}/producto/codigo/${codigo}`;
      } else {
        urlCodigo = `${initialENDPOINT}/producto/codigo`;
      }

      const response = await axios(
        urlCodigo,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          redirect: "follow",
        }
      );

      if (response.data.data.status === 201) {

        isMounted &&
          setFormData((prevalue) => {
            return {
              ...prevalue,
              productoCodigoNombre: "Producto no encontrado",
              nombreMarca: "Marca no encontrada",
              precioProducto: "",
              cantidadProducto: 0,
              stockProducto: "",
            };
          });

        getProductsList();

        var codigoProducto = document.getElementById('codigoProducto');
        codigoProducto.value = '';
      }

      if (response.data.data.status === 200) {

        const url = `${initialENDPOINT}/pedidodetalle`;

        const datos = {
          pedido: orderID,
          producto: response.data.data.message[0].id,
          precio: response.data.data.message[0].precio,
          cantidad: formData.cantidadProducto || 1,
          codigo: codigo
        };

        console.info(datos);

        try {
          const response = await axios.post(url, datos, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          switch (response.data.status) {
            case 500:
              notifyError(`Producto sin stock!`);
              break;
            case 501:
              notifyError(`Producto inexistente!`);
              break;
            default:
              notify(`Producto agregado con exito!`);
              break;

          }
        } catch (error) {
          console.error('Error:', error.message);
        }

        getProductsList();

        setIsDisabled(false);

        var codigoProducto = document.getElementById('codigoProducto');
        codigoProducto.value = '';

      }
    } catch (error) {
      console.log('entro al error');
      console.log(error);
      isMounted &&
        setFormData({
          stockProducto: ''
        });
    }

  };

  const handleSearch = () => {

    setENDPOINT(
      `${initialENDPOINT}/producto?pagina=1&nombre=${formData.productoNombre || ''}&nombreMarca=${formData.nombreMarca || ''}`
    );
  };


  const updateProductoDetalle = async () => {

    const data = {
      precio: precio,
      cantidad: cantidad
    };

    const apiUrl = `${initialENDPOINT}/pedidodetalle/producto/${id}`

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    axios.put(apiUrl, data, config)
      .then(response => {
        setModalOpen(false);
        getProductsList();
        notify(`Producto editado!`);
      })
      .catch(error => {
        console.error('Error en la solicitud PUT:', error);
      });
  }

  useEffect(() => {
    !token && goToLogin();
    const random = Date.now();
    newQuery &&
      setENDPOINT(`${initialENDPOINT}/producto?pagina=1&random=${random}`);
    setInputMsg("");
  }, [newQuery]);

  useEffect(() => {
    formData.detalle && handleQuantity();
  }, [formData.detalle]);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);
  return (
    <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">
      <h6 className="pb-3">Detalles del pedido</h6>

      <Form.Group className="mb-3 col-3">
        <Form.Label>Codigo de producto</Form.Label>
        <InputGroup>
          <Form.Control
            type="text"
            name="codigoProducto"
            id="codigoProducto"
            defaultValue={formData.codigoProducto}
            placeholder="Ingrese el codigo"
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleCodigoProducto(e.target.value, e);
              }
            }}
          />
          <InputGroup.Text
            style={{ cursor: "pointer" }}
            onClick={() => setNewQuery(true)}
          >
            <i className="fa fa-search" aria-hidden="true"></i>
          </InputGroup.Text>
        </InputGroup>
        {formData.nombreProducto && (
          <Form.Text
            className={
              formData.nombreProducto !== "Producto no encontrado"
                ? `text-success ms-2`
                : `text-danger ms-2`
            }
          >
            {formData.nombreProducto}
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group className="mb-3 col-2">
        <Form.Label>Precio</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            name="precioProducto"
            defaultValue={formData.precioProducto}
            onChange={(e) => handleChange(e)}
            min="0"
          />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3 col-2">
        <Form.Label>Cantidad</Form.Label>
        <Form.Control
          type="number"
          name="cantidadProducto"
          value={formData.cantidadProducto || 1}
          onChange={(e) => handleChange(e)}
          min="0"
        />
      </Form.Group>
      <Form.Group className="d-flex align-items-end justify-content-end mb-3 col-2">
        <Button onClick={handleProductAdd} disabled={isDisabled}>
          Agregar
        </Button>
      </Form.Group>
      {inputMsg && (
        <Form.Text className="text-danger mt-0">{inputMsg}</Form.Text>
      )}
      <ProductsTable
        formData={formData}
        handleProductDelete={handleProductDelete}
        handleProductEdit={handleProductEdit}
      />
      <div>
        <Form.Text className="me-2">
          Cantidad de articulos: {formData.cantidadDeArticulos || 0}
        </Form.Text>
        <Form.Text> | </Form.Text>
        <Form.Text className="ms-2">
          Cantidad de unidades: {formData.cantidadDeUnidades || 0}
        </Form.Text>
      </div>
      <MyModal
        show={newQuery}
        onHide={() => setNewQuery(false)}
        title="Buscar producto"
      >
        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>Productos</Form.Label>
            <Form.Control
              type="text"
              name="productoNombre"
              defaultValue={formData.productoNombre}
              placeholder="Ingrese el nombre"
              onChange={(e) => handleChange(e)}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3 col-6">
            <Form.Label>Marca</Form.Label>
            <Form.Control
              type="text"
              name="nombreMarca"
              placeholder="Ingrese la marca"
              onChange={(e) => handleChange(e)}
            ></Form.Control>
          </Form.Group>
          <Button
            type="submit"
            className="mt-auto mb-3 col-3"
            style={{ marginLeft: '15px' }}
            onClick={() => handleSearch()}
          >
            Buscar
          </Button>
        </Row>
        {errMsg && <p>{errMsg}</p>}
        <DataTable
          columns={queriesColumns}
          data={data}
          striped
          customStyles={{
            rows: {
              highlightOnHoverStyle: {
                backgroundColor: "rgb(230, 244, 244)",
                borderBottomColor: "#FFFFFF",
                outline: "1px solid #FFFFFF",
              },
            },
            pagination: {
              style: {
                border: "none",
              },
            },
          }}
          pointerOnHover
          highlightOnHover
          progressPending={loading}
          progressComponent={<Loader />}
          noDataComponent={<Vacio />}
          pagination
          paginationServer
          paginationComponentOptions={{
            rowsPerPageText: "Filas por página",
            rangeSeparatorText: "de",
          }}
          paginationDefaultPage
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          persistTableHead
        />
      </MyModal>

      <MyModal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        title="Editar Producto"
      >
        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>P/Unitario</Form.Label>
            <Form.Control
              type="text"
              name="precioUnitarioDetalle"
              placeholder="Ingrese el precio unitario"
              onChange={(e) => setPrecio(e.target.value)}
              value={precio}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6">
            <Form.Label>Cantidad</Form.Label>
            <Form.Control
              type="text"
              name="cantidadDetalle"
              placeholder="Ingrese la cantidad"
              onChange={(e) => setCantidad(e.target.value)}
              value={cantidad}
            />
          </Form.Group>
          <Button
            type="submit"
            className="mt-auto mb-3 col-3"
            style={{ marginLeft: '15px' }}
            onClick={updateProductoDetalle}
          >
            Guardar
          </Button>
        </Row>
      </MyModal>
    </Row>
  );
};

export default Productos;
