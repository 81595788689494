import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import axios from "axios"; // Asegúrate de instalar axios
import NnProductsTable from "./NnProductsTable";
import { useParams } from "react-router-dom";

const ProductosNN = ({ handleChange, formData, setFormData }) => {
  const [inputMsg, setInputMsg] = useState("");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const { orderID } = useParams();

  const fetchProductsByPedido = async () => {
    try {
      const url = `${initialENDPOINT}/pedidodetallenn/pedido/${orderID}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const updatedProducts = response.data.original || [];
      setFormData((prevalue) => ({
        ...prevalue,
        detalleNN: updatedProducts,
      }));
    } catch (error) {
      console.error("Error al obtener los productos:", error);
    }
  };

  const handleProductAdd = async () => {
    if (
      !formData.descripcionNN ||
      !formData.precioPedido ||
      !formData.cantidadProductoNN
    ) {
      setInputMsg("Debes completar todos los campos");
      return;
    }

    try {
      const url = `${initialENDPOINT}/pedidodetallenn`;

      const payload = {
        descripcion: formData.descripcionNN,
        precio: formData.precioPedido,
        pedido: formData.pedido || orderID,
        cantidad: formData.cantidadProductoNN,
      };

      const response = await axios.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      
      const updatedData = response.data?.data?.original?.results || [];
      setFormData((prevalue) => ({
        ...prevalue,
        detalleNN: updatedData,
        descripcionNN: "",
        precioPedido: "",
        cantidadProductoNN: "",
      }));

      setInputMsg("");
      await fetchProductsByPedido();
    } catch (error) {
      console.error("Error al agregar el producto:", error);
      setInputMsg("Hubo un error al agregar el producto. Intenta nuevamente.");
    }
  };

  const handleProductDelete = async (id) => {
    try {

      const url = `${initialENDPOINT}/pedidodetallenn/${id}`;
  

      // Hacer la solicitud DELETE
      await axios.delete(url,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      await fetchProductsByPedido();
      
      setInputMsg(""); // Limpiar cualquier mensaje previo
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
      setInputMsg("Hubo un error al eliminar el producto. Intenta nuevamente.");
    }
  };

  
  useEffect(() => {
    const initializeProducts = async () => {
      if (orderID) {
        await fetchProductsByPedido(orderID);
      }
    };
    initializeProducts();
  }, [orderID]);

  return (
    <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">
      <h6 className="pb-3">Productos NN</h6>
      <Form.Group className="mb-3 col-4">
        <Form.Label>Descripción</Form.Label>
        <Form.Control
          type="text"
          name="descripcionNN"
          value={formData.descripcionNN || ""}
          placeholder="Ingrese la descripción"
          onChange={(e) => handleChange(e)}
        ></Form.Control>
      </Form.Group>
      <Form.Group className="mb-3 col-3">
        <Form.Label>Precio</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            name="precioPedido"
            value={formData.precioPedido || ""}
            onChange={(e) => handleChange(e)}
            min="0"
          />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3 col-3">
        <Form.Label>Cantidad</Form.Label>
        <Form.Control
          type="number"
          name="cantidadProductoNN"
          value={formData.cantidadProductoNN || ""}
          onChange={(e) => handleChange(e)}
          min="0"
        />
      </Form.Group>
      <Form.Group className="d-flex align-items-end justify-content-end mb-3 col-2">
        <Button onClick={handleProductAdd}>Agregar</Button>
      </Form.Group>
      {inputMsg && (
        <Form.Text className="text-danger mt-0">{inputMsg}</Form.Text>
      )}
      <NnProductsTable
        formData={formData.detalleNN}
        handleProductDelete={handleProductDelete}
      />
    </Row>
  );
};

export default ProductosNN;
